import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";

const callToActionDatas = {
    titless: "How Operon Strategist Will Help You in Import License for Medical Device?", 
    subtitless: "Operon strategist is the leading medical device regulatory consulting company. We help our clients in making technical dossier for the import licensing of  medical devices in India, we also solve the queries raised by the regulators, and we also providie turnkey services, system implementation, training, licensing, regulatory approvals and certifications. Apart from India we also serve clients from Saudi Arabia who wish to import medial devices from Saudi Arabia. ",
  
}


const Callto2ActionFive = () => {
    return (
        <div>

        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title">{callToActionDatas.titless}</h2>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className="subtitle">{callToActionDatas.subtitless}</h6>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    {/* <div className="call-to-btn text-center">
                                        <a className="btn-default btn-icon" href="#">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></a>
                                    </div> */}
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         
                                    </div>
    )
}
export default Callto2ActionFive;