import React , {useState} from 'react';
import { db } from '../Firebase/Firebase';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';



function ContactForm({props , formStyle}) {
    
    
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [Company, setCompany] = useState('');
    const [Phone, setPhone] = useState('');
    const [option, setoption] = useState('');

    function onClick(){
        addDoc(collection(db, "ContactUs"), {
          name: name,
          email: email,
          Company: Company,
          Phone: Phone,
          option:option,
          createdAt : serverTimestamp(),
          unread: 1,
        });
        alert("Submitted")
      }

     

    return (
        <form className={`${formStyle}`} action=""  autoComplete="off"  >
            <div className="form-group">
                <input 
                type="text"
                name="setName"
                placeholder=" Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                name="Company name"
                placeholder="Company Name"
                value={Company}
                onChange={(e) => setCompany(e.target.value)}
                required
                />
            </div>

            <div className="form-group">
                <input 
                type="text"
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                required
                />
            </div>


            <div className="form-group">
                <input 
                type="number"
                name="Phone Number"
                placeholder="Phone Number"
                value={Phone}
                onChange={(e)=>setPhone(e.target.value)}
                required
                />
            </div>
            
            <div class="form-group">
	<select value={option} onChange={e=> setoption(e.target.value)} >
		<option value="">Select Option</option>
		<option value="Cosmetic">Cosmetic</option>
		<option value="Medical">Medical</option>
	</select>
</div>

            {/* <div className="form-group">
                <textarea 
                name="message"
                placeholder="Your Message"
                required
                >
                </textarea>
            </div> */}

            <div className="form-group">
                <button className="btn-default btn-large" onClick={()=> onClick()} disabled={!name || !email || !Phone || !option || !Company } style={{marginTop: "20px"}}>Submit Now</button>
            </div> 

        
        </form>
    )
}
export default ContactForm;
