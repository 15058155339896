import { useState, useRef } from "react";
import { FiMenu } from "react-icons/fi";
import {Link} from "react-router-dom";
import logo from '../Images/AK logo.svg'
import logo2 from '../Images/Ak logo white.svg'
import Nav from "../pages/Nav";
import MobileMenu from "../pages/MobileMenu";
import Darkmode from "../pages/Darkmode";
import useStickyHeader from "../pages/useStickyHeader";

const HeaderOne = ({ btnStyle, HeaderSTyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };
  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? "sticky" : ""}`;
  const { clientHeight } = ref;

  const checkChange = (value) => {
    setCheck(value);
  };

  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default ${HeaderSTyle} ${headerClasses}`}
      >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-4">
              {/* logo-contact */}
        
           <div className="logo" >
                <Link to={process.env.PUBLIC_URL + "/"}>
                    <img  className="logo-light" src={logo2} alt="Corporate Logo"style={{height:"50px" ,marginTop:"8px"}} />
                    <img   className="logo-dark" src={logo} alt="Corporate Logo"style={{height:"50px" ,marginTop:"8px"}} />
                </Link>
        </div>
            </div>
            <div className="col-lg-9 col-md-6 col-8 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div className="header-btn"></div>
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <span
                      className="hamberger-button"
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
                <Darkmode />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderOne;
