import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";

const callToActionData = {
    title: "Import License Registration Phases", 
    subtitle: "CDSCO Import license for medical devices is regulated in India that any industry or an individual having license (wholesale and/or manufacturing license issued under central drug standard control organization (CDSCO), Drugs and cosmetics act, 1940 and can import medical devices into India.Under the new dispensation, foreign manufacturers have to apply for registration certificate for their manufacturing premises and the individual drugs to be imported. The applications can be made by authorized agents of foreign firms in India. The documents required for registration certificates have been clearly specified in the amendments. The validity of registration certificates will be 3 years from the date on which these are issued. ",
  
}


const CalltoActionFive = () => {
    return (
        <div>

        <div className="rn-callto-action clltoaction-style-default style-5">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper theme-shape">
                    <div className="col-lg-12">
                        <div className="inner">
                            <div className="content text-center">
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title">{callToActionData.title}</h2>
                                </ScrollAnimation>
                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h6 className="subtitle">{callToActionData.subtitle}</h6>
                                </ScrollAnimation>

                                <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    {/* <div className="call-to-btn text-center">
                                        <a className="btn-default btn-icon" href="#">{callToActionData.btnText} <i className="icon"><FiArrowRight /></i></a>
                                    </div> */}
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
         
                                    </div>
    )
}
export default CalltoActionFive;