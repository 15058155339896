import firebase from 'firebase/compat/app';
import 'firebase/compat/database'
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getFirestore } from "firebase/firestore";
import "firebase/firestore";
import { getStorage } from "firebase/storage";
// import firestore from 'firebase';




const app = firebase.initializeApp({

  apiKey: "AIzaSyAXxkXz-YAY4lsZddHJYFwvh7yqSNyFtaA",
  authDomain: "ak-consultant-e0d43.firebaseapp.com",
  projectId: "ak-consultant-e0d43",
  storageBucket: "ak-consultant-e0d43.appspot.com",
  messagingSenderId: "221156880929",
  appId: "1:221156880929:web:60b6252a7b640142162960"


});


export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = app.auth()
export default app



