import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const SectionTitle = ({subtitle,subtitle1,subtitless,titless, title,title1, description,description1, textAlign, radiusRounded,li1,li2,li3,li4,}) => {
    return (
        <div className={`section-title ${textAlign}`}>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <h4 className={`subtitle ${radiusRounded}`}><span className="theme-gradient" dangerouslySetInnerHTML={{__html: subtitle}}></span></h4>
            </ScrollAnimation>
            
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <h2 className="title w-600 mb--20" dangerouslySetInnerHTML={{__html: title}}></h2>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <p className="description b1" dangerouslySetInnerHTML={{__html: description}}></p>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <h2 className="title w-600 mb--20" dangerouslySetInnerHTML={{__html: subtitle1}}></h2>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <p className="description b1" dangerouslySetInnerHTML={{__html: li1}}></p>
                <p className="description b1" dangerouslySetInnerHTML={{__html: li2}}></p>
                <p className="description b1" dangerouslySetInnerHTML={{__html: li3}}></p>
                <p className="description b1" dangerouslySetInnerHTML={{__html: li4}}></p>

            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <h2 className="title w-600 mb--20" dangerouslySetInnerHTML={{__html: title1}}></h2>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <p className="description b1" dangerouslySetInnerHTML={{__html: description1}}></p>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <h2 className="title w-600 mb--20" dangerouslySetInnerHTML={{__html: titless}}></h2>
            </ScrollAnimation>
            <ScrollAnimation 
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}>
                <p className="description b1" dangerouslySetInnerHTML={{__html: subtitless}}></p>
            </ScrollAnimation>

        </div>
    )
}
export default SectionTitle;